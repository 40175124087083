import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Product = ({ pageContext }) => {
  return (
    <Layout wider>
      <Seo title={pageContext?.data?.title} />

      <section className="produkter-single">
        <div className="container-fluid wider">
          <div className="row">
            <div className="col-md-5">
              <img
                className="img-fluid"
                src={
                  pageContext?.data?.data?.featuredImage?.node?.localFile
                    ?.publicURL
                }
                alt={pageContext?.data?.title}
              />
            </div>
            <div className="col-md-7">
              <div className="produkter-single-col-2">
                <p id="breadcrumbs">
                  <span>
                    <Link to="/">Home</Link>
                    {" / "}
                    <Link to="/produkter/">Produkter</Link>
                    {pageContext?.data?.data?.wpParent?.node?.wpParent
                      ?.node && (
                      <>
                        {" / "}
                        <Link
                          to={
                            pageContext?.data?.data?.wpParent?.node?.wpParent
                              ?.node.uri
                          }
                        >
                          {
                            pageContext?.data?.data?.wpParent?.node?.wpParent
                              ?.node.title
                          }
                        </Link>
                      </>
                    )}
                    {pageContext?.data?.data?.wpParent?.node && (
                      <>
                        {" / "}
                        <Link to={pageContext?.data?.data?.wpParent?.node?.uri}>
                          {pageContext?.data?.data?.wpParent?.node?.title}
                        </Link>
                      </>
                    )}
                    {" / "}
                    <span>{pageContext?.data?.title}</span>
                  </span>
                </p>
                <h1>
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pageContext?.data?.title,
                      }}
                    />
                  }
                </h1>
                <div
                  className="produkter-single-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: pageContext?.data?.data?.content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Product
